<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
      >
        {{ $t("app.choose_module") }}
      </h4>

      <div class="col-md-12 text-center" style="padding: 0">
        <select
          class="form-control"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
          v-model="row.model_type"
          v-on:change="fetchModule"
        >
          <option v-for="(mod, idx) in modules" :key="idx" :value="mod.value">
            {{ $t("nav." + mod.value) }}
          </option>
        </select>
      </div>

      <div
        v-if="hasChild"
        class="col-md-12 text-center"
        style="margin-top: 10px; padding: 0"
      >
        <span
          v-if="dataLoading"
          class="spinner-grow spinner-grow-sm mr-1"
          role="status"
          aria-hidden="true"
        >
        </span>

        <ModelSelect
          v-if="!dataLoading"
          :options="dataModelSelect"
          v-model="row.model_id"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
          placeholder=""
          @input="onSelectChange"
          :style="$i18n.locale == 'ar' ? 'text-align: right' : ''"
        >
        </ModelSelect>

        <!-- <select 
                    v-if="!dataLoading"
                    class="form-control" 
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : ''"
                    v-model="row.model_id"
                    v-on:change="onSelectChange">
                    <option 
                        v-for="(row, idx) in data" 
                        :key="idx"
                        :value="row.id">
                        {{ row[$i18n.locale]['title'] }}
                    </option>
                </select> -->
      </div>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";
import { ModelSelect } from "vue-search-select";

export default {
  name: "CardModule",
  components: {
    ModelSelect,
  },
  props: ["model_type", "model_id", "lecturer_id"],
  data() {
    return {
      auth: {
        access_token: "",
      },
      row: {
        model_id: this.$props.model_id,
        model_type: this.$props.model_type,
        lecturer_id: this.$props.lecturer_id,
      },
      modules: [
        {
          key: "emergency_consultations",
          value: "emergencyConsultations",
        },
        {
          key: "appointment_consultations",
          value: "appointmentConsultations",
        },
        {
          key: "chat_consultations",
          value: "chatConsultations",
        },
        {
          key: "video_consultations",
          value: "videoConsultations",
        },
        {
          key: "meeting_conventions",
          value: "meetingConventions",
        },
        {
          key: "voice_consultations",
          value: "voiceConsultations",
        },
        {
          key: "Modules\\Faqs\\Entities\\Faq",
          value: "faqs",
        },
        {
          key: "Modules\\Books\\Entities\\Book",
          value: "books",
        },
        {
          key: "online_courses",
          value: "onlineCourses",
        },
        {
          key: "offline_courses",
          value: "offlineCourses",
        },
      ],

      data: [],
      dataModelSelect: [],
      hasChild: false,
      dataLoading: true,
    };
  },
  watch: {
    lecturerId: function (new_val) {
      this.$props.lecturer_id = new_val;
      this.fetchData();
    },
  },
  mounted() {},
  created() {
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    if (this.$props.model_type) {
      this.fetchData();
    }
  },
  methods: {
    onSelectChange() {
      const data = {
        model_type: this.row.model_type,
        model_id: this.row.model_id,
      };
      this.$emit("selectChange", data);
    },

    fetchModule() {
      this.fetchData();
      this.onSelectChange();
    },

    fetchData() {
      const array = ["books", "onlineCourses", "offlineCourses"];
      this.hasChild = false;
      if (!array.includes(this.row.model_type)) {
        this.row.model_id = "";
      }
      if (array.includes(this.row.model_type)) {
        this.hasChild = true;
        this.dataLoading = true;
        this.axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
          Authorization: `Bearer ` + this.auth.access_token,
        };
        const options = {
          url: window.baseURL + "/" + this.row.model_type,
          method: "GET",
          data: {},
          params: {
            status: "active",
            paginate: 1000,
            lecturer_id: this.$props.lecturer_id,
          },
        };
        this.axios(options)
          .then((res) => {
            this.dataLoading = false;
            this.data = res.data.data.rows;

            // transform to modelSelect
            this.dataModelSelect = [];
            this.data.forEach((element) => {
              this.dataModelSelect.push({
                value: element["id"],
                text: element[this.$i18n.locale]["title"],
              });
            });
          })
          .catch((err) => {
            this.dataLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          });
      }
    },
  },
};
</script>
